export default {
  user: {
    entity: 'User | Users',
    attributes: {
      id: 'Employment id',
      email: 'Email',
      password: 'Password',
      newPassword: 'New Password',
      confirmPassword: 'Confirm Password',
      name: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      bio: 'Bio',
      role: 'Role',
      reporterId: 'Direct Supervisor',
      birthday: 'Birthday',
      phoneNumber: 'Phone Number',
      emergencyNumber: 'Emergency Number',
      address: 'Address',
      jobTitle: 'Job Title',
      jobType: 'Job type',
      workingHoursPerDay: 'Working Hours Per Day',
      timezone: 'Timezone',
      department: 'Department',
      title: 'Job Title',
      mobileNumber: 'Mobile Number',
      team: 'Team',
      office: 'Office',
      asset: 'Asset',
      project: 'Project',
      level: 'Level',
      employeeId: 'Employee Id',
      avatar: 'Profile Picture',
      birthdate: 'Birth Date',
      hiringDate: 'Hiring Date',
      socialMediaLinks: 'Social Media Links',
      startDate: 'Hiring Date',
      facebook: 'Facebook',
      twitter: 'Twitter',
      linkedin: 'LinkedIn',
      github: 'Github',
      personalKudos: 'Personal Kudos'
    },
    types: {
      full: 'Full Time',
      part: 'Part Time'
    },
    roles: {
      admin: 'Admin',
      employee: 'Employee'
    }
  },
  supervisor: {
    entity: 'Supervisor | Supervisors'
  },
  leave: {
    entity: 'Leave | Leaves',
    attributes: {
      name: 'Name',
      totalBalance: 'Total Balance',
      carryOverBalance: 'Carry Over Balance',
      recurrence: 'Recurrence',
      googleCalendarId: 'Google Calendar Id',
      recurrences: {
        weeks: 'Weekly',
        months: 'Monthly',
        years: 'Annually'
      },
      recurrenceOptions: [
        {key: 'weeks', value: 'Weekly'},
        {key: 'months', value: 'Monthly'},
        {key: 'years', value: 'Annually'}
      ],
      startDate: 'Start Date',
      endDate: 'End Date',
      unit: 'Acceptable Units',
      units: {
        full_day: 'Full Day',
        half_day: 'Half Day',
        hours: 'Hours'
      },
      requiresApproval: 'Requires Approval',
      maxConsecutiveDays: 'Max Consecutive Days',
      canOverlap: 'Can Overlap'
    }
  },
  document: {
    entity: 'Document | Documents',
    attributes: {
      title: 'Title',
      filename: 'File Name',
      file: 'File'
    }
  },
  team: {
    entity: 'Team | Teams',
    attributes: {
      name: 'Name',
      duties: 'Duties'
    }
  },
  permission: {
    entity: 'Permission | Permissions'
  },
  company: {
    entity: 'Company | Companies',
    attributes: {
      companyName: 'Company Name',
      industry: 'Industry',
      employeeCountRange: 'Employee Count Estimation',
      logo: 'Logo',
      email: 'Admin Email',
      firstName: 'Admin First Name',
      lastName: 'Admin Last Name',
      subdomain: 'Subdomain',
      jiraURL: 'Jira URL',
      jiraUsername: 'Jira Username',
      jiraPassword: 'Jira Password',
      industries: {
        INFORMATION_TECHNOLOGY_AND_SERVICES: 'Information Technology and Services',
        HOSPITAL_HEALTH_CARE: 'Hospital & Health Care',
        CONSTRUCTION: 'Construction',
        EDUCATION_MANAGEMENT: 'Education Management',
        RETAIL: 'Retail',
        FINANCIAL_SERVICES: 'Financial Services',
        ACCOUNTING: 'Accounting',
        COMPUTER_SOFTWARE: 'Computer Software',
        AUTOMOTIVE: 'Automotive',
        HIGHER_EDUCATION: 'Higher Education',
        MARKETING_ADVERTISING: 'Marketing & Advertising',
        GOVERNMENT_ADMINISTRATION: 'Government Administration',
        BANKING: 'Banking',
        HEALTH_WELNESS_FITNESS: 'Health, Welness & Fitness',
        REAL_ESTATE: 'Real Estate',
        TELECOMMUNICATIONS: 'Telecommunications',
        OIL_ENERGY: 'Oil & Energy',
        FOOD_BEVERAGES: 'Food & Beverages',
        HOSPITALITY: 'Hospitality',
        MECHANICAL_OR_INDUSTRIAL_ENGINEERING: 'Mechanical or Industrial Engineering',
        ELECTRICAL_ELECTRONIC_MANUFACTURING: 'Electrical & Electronic Manufacturing',
        PRIMARY_SECONDARY_EDUCATION: 'Primary/Secondary Education',
        INSURANCE: 'Insurance',
        INTERNET: 'Internet',
        HUMAN_RESOURCES: 'Human Resources',
        MEDICAL_PRACTICE: 'Medical Practice',
        TRANSPORTATION_TRUCKING_RAILROAD: 'Transportation/Trucking/Railroad',
        CONSUMER_SERVICES: 'Consumer Services',
        MANAGEMENT_CONSULTING: 'Management Consulting',
        PHARMACEUTICALS: 'Pharmaceuticals',
        CIVIL_ENGINEERING: 'Civil Engineering',
        DESIGN: 'Design',
        RESEARCH: 'Research',
        RESTAURANTS: 'Restaurants',
        LOGISTICS_SUPPLY_CHAIN: 'Logistics & Supply Chain',
        ARCHITECTURE_PLANNING: 'Architecture & Planning',
        LAW_PRACTICE: 'Law Practice',
        APPAREL_FASHION: 'Apparel & Fashion',
        CONSUMER_GOODS: 'Consumer Goods',
        FACILITIES_SERVICES: 'Facilities Services',
        FOOD_PRODUCTION: 'Food Production',
        NON_PROFIT_ORGANIZATION_MANAGEMENT: 'Non-profit Organization Management',
        ENTERTAINMENT: 'Entertainment',
        MACHINERY: 'Machinery',
        CHEMICALS: 'Chemicals',
        ARTS_CRAFTS: 'Arts & Crafts',
        WHOLESALE: 'Wholesale',
        UTILITIES: 'Utilities',
        LEGAL_SERVICES: 'Legal Services',
        FARMING: 'Farming',
        MINING_METALS: 'Mining & Metals',
        AIRLINES_AVIATION: 'Airlines/Aviation',
        LEISURE_TRAVEL_TOURISM: 'Leisure, Travel & Tourism',
        SPORTING_GOODS: 'Sporting Goods',
        BUILDING_MATERIALS: 'Building Materials',
        MUSIC: 'Music',
        ENVIROMENTAL_SERVICES: 'Enviromental Services',
        PROFESSIONAL_TRAINING_COACHING: 'Professional Training & Coaching',
        MEDICAL_DEVICE: 'Medical Device',
        INDIVIDUAL_FAMILY_SERVICES: 'Individual & Family Services',
        COSMETICS: 'Cosmetics',
        MENTAL_HEALTH_CARE: 'Mental Health Care',
        AVIATION_AND_AEROSPACE: 'Aviation and Aerospace',
        STAFFING_RECRUITING: 'Staffing & Recruiting',
        INDUSTRIAL_AUTOMATION: 'Industrial Automation',
        GRAPHIC_DESIGN: 'Graphic Design',
        SECURITY_INVESTIGATIONS: 'Security & Investigations',
        IMPORT_AND_EXPORT: 'Import and Export',
        PUBLIC_RELATIONS_AND_COMMUNICATIONS: 'Public Relations and Communications',
        TEXTILES: 'Textiles',
        MILITARY: 'Military',
        BROADCAST_MEDIA: 'Broadcast Media',
        BIOTECHNOLOGY: 'Biotechnology',
        MEDIA_PRODUCTION: 'Media Production',
        BUSINESS_SUPPLIES_EQUIPMENT: 'Business Supplies & Equipment',
        COMPUTER_NETWORKING: 'Computer Networking',
        WRITING_EDITING: 'Writing & Editing',
        CONSUMER_ELECRONICS: 'Consumer Elecronics',
        INTERNATIONAL_TRADE_AND_DEVELOPMENT: 'International Trade and Development',
        EVENTS_SERVICES: 'Events Services',
        PHOTOGRAPHY: 'Photography',
        RENEWABLES_ENVIRNOMENT: 'Renewables & Envirnoment',
        COMPUTER_HARDWARE: 'Computer Hardware',
        CIVIC_AND_SOCIAL_ORGANIZATION: 'Civic and Social Organization',
        FURNITURE: 'Furniture',
        DEFENSE_SPACE: 'Defense & Space',
        COMPUTER_NETWORK_SECURITY: 'Computer & Network Security',
        PRINTING: 'Printing',
        FINE_ART: 'Fine Art',
        RELIGIOUS_INSTITUTIONS: 'Religious Institutions',
        INVESTMEND_MANAGEMENT: 'Investmend Management',
        LAW_ENFORCEMENT: 'Law Enforcement',
        PUBLISHING: 'Publishing',
        INFORMATION_SERVICES: 'Information Services',
        MARITIME: 'Maritime',
        OUTSOURCING_OFFSHORING: 'Outsourcing/Offshoring',
        WAREHOUSING: 'Warehousing',
        E_LEARNING: 'E-learning',
        EXECUTIVE_OFFICE: 'Executive Office',
        GOVERNMENT_RELATIONS: 'Government Relations',
        ANIMATION: 'Animation',
        SEMICONDUCS: 'Semiconducs',
        SUPERMARKETS: 'Supermarkets',
        PROGRAM_DEVELOPMENT: 'Program Development',
        PUBLIC_SAFETY: 'Public Safety',
        PLASTICS: 'Plastics',
        ALTERNATIVE_MEDICINE: 'Alternative Medicine',
        PERFORMING_ARTS: 'Performing Arts',
        ONLINE_MEDIA: 'Online Media',
        MOTION_PICTURES_FILM: 'Motion Pictures & Film',
        COMMERCIAL_REAL_ESTATE: 'Commercial Real Estate',
        JUDICIARY: 'Judiciary',
        PACKAGING_AND_CONTAINERS: 'Packaging and Containers',
        LUXURY_GOODS_JEWELRY: 'Luxury Goods & Jewelry',
        VETERINARY: 'Veterinary',
        COMPUTER_GAMES: 'Computer Games',
        INVESTMENT_BANKING: 'Investment Banking',
        MARKET_RESEARCH: 'Market Research',
        INTERNATIONAL_AFFAIRS: 'International Affairs',
        WINE_SPIRITS: 'Wine & Spirits',
        NEWSPAPERS: 'Newspapers',
        TRANSLATION_LOCALISATION: 'Translation & Localisation',
        RECREATIONAL_FACILITIES_SERVICES: 'Recreational Facilities & Services',
        PAPER_FOREST_PRODUCTS: 'Paper & Forest Products',
        CAPITAL_MARKETS: 'Capital Markets',
        PUBLIC_POLICY: 'Public Policy',
        PACKAGE_FREIGHT_DELIVERY: 'Package/Freight Delivery',
        LIBRARIES: 'Libraries',
        WIRELESS: 'Wireless',
        GAMBLING_CASINOS: 'Gambling & Casinos',
        VENTURE_CAPITAL_PRIVATE_EQUITY: 'Venture Capital & Private Equity',
        GLASS_CERAMICS_CONCRETE: 'Glass, Ceramics & Concrete',
        PHILANTHROPY: 'Philanthropy',
        RANCHING: 'Ranching',
        DAIRY: 'Dairy',
        MUSEUMS_AND_INSTITUTIONS: 'Museums and Institutions',
        SHIPBUILDING: 'Shipbuilding',
        THINK_THANKS: 'Think Thanks',
        POLITICAL_ORGANIZATION: 'Political Organization',
        FISHERY: 'Fishery',
        FUND_RAISING: 'Fund-Raising',
        TOBACCO: 'Tobacco',
        RAILROAD_MANUFACTURE: 'Railroad Manufacture',
        ALTERNATIVE_DISPUTE_RESOLUTION: 'Alternative Dispute Resolution',
        NANOTECHNOLOGY: 'Nanotechnology',
        LEGISLATIVE_OFFICE: 'Legislative Office'
      }
    }
  },
  office: {
    entity: 'Office | Offices',
    attributes: {
      name: 'Name',
      timezone: 'Timezone',
      fullTimeWorkingDay: 'Full-time Working Day',
      weekends: 'Weekends',
      businessHoursStart: 'Business Hours: Start Time',
      businessHoursEnd: 'Business Hours: End Time',
      businessHours: 'Business Hours',
      googleCalendarId: 'Google Calendar Id'
    }
  },
  kudo: {
    entity: 'Kudo | Kudos',
    creator: 'Creator',
    assignee: 'Assignee',
    attributes: {
      title: 'Title',
      description: 'Description',
      badge: 'Badge',
      creator_id: 'Creator Id',
      assignee_id: 'Assignee Id',
      creator_name: 'Creator Name',
      assignee_name: 'Assignee Name'
    }
  },
  asset: {
    entity: 'asset | assets',
    attributes: {
      name: 'Name',
      description: 'Description',
      assetType: 'Type',
      serialNumber: 'Serial Number',
      assigneeOffice: 'Office',
      assigneeUser: 'Employee',
      office: 'Office',
      user: 'User',
      assetTypes: {
        LAPTOP: 'Laptop',
        PC: 'PC',
        SCREEN: 'Screen',
        MOBILE: 'Mobile',
        TABLET: 'Tablet'
      }
    }
  },
  theme: {
    entity: 'Theme | Themes',
    attributes: {
      primaryColor: 'Primary Color',
      secondaryColor: 'Secondary Color'
    }
  },
  department: {
    entity: 'Department | Departments',
    attributes: {
      name: 'Name',
      description: 'Description'
    }
  },
  userLeave: {
    entity: 'Leave | Leaves',
    attributes: {
      name: 'Name',
      leaveType: 'Leave Type',
      totalBalance: 'Total Balance',
      recurrence: 'Recurrence',
      startDate: 'Start Date',
      endDate: 'End Date',
      pendingApproval: 'Pending',
      approved: 'Approved',
      rejected: 'Rejected',
      jiraTask: 'Jira Task',
      unit: 'Unit',
      units: {
        full_day: 'Full Day',
        half_day: 'Half Day',
        hours: 'Hours',
        half_day_morning: 'Morning',
        half_day_evening: 'Evening'
      }
    }
  },
  leaveRequest: {
    entity: 'Leave Request | Leave Requests',
    attributes: {
      date: 'Date',
      from: 'From',
      to: 'To',
      startDate: 'Start Date',
      endDate: 'End Date',
      unit: 'Unit',
      hours: 'hrs',
      units: {
        full_day: 'Full Day',
        half_day_morning: 'Half-day Morning',
        half_day_evening: 'Half-day Evening',
        hours: 'Hours'
      },
      leaveName: 'Leave Type',
      user: 'User',
      status: 'Status',
      duration: 'Duration',
      note: 'Notes',
      dates: 'Dates',
      managedBy: 'Managed By',
      approvedBy: 'Approved By',
      rejected_by: 'Rejected By',
      statuses: {
        pending: 'Pending',
        approved: 'Approved',
        partially_approved: 'Partially Approved',
        rejected: 'Rejected',
        canceled: 'Cancelled'
      },
      year: 'Year'
    }
  },
  holiday: {
    entity: 'Holiday | Holidays',
    attributes: {
      name: 'Name',
      startDate: 'From',
      endDate: 'To',
      officeId: 'Office ID',
      office: 'Office',
      year: 'Year'
    }
  },
  calendar: {
    entity: 'Calendar | Calendars',
    attributes: {
      name: 'Name',
      color: 'Color',
      isPrivate: 'Private',
      createdBy: 'Created By'
    },
    events: {
      entity: 'Event | Events',
      leave: '{userName}\'s {leaveName} Leave - {leaveUnit}',
      birthday: 'Happy Birthday {userName}!',
      workAnniversary: 'Happy work anniversary {userName}!'
    }
  },
  goal: {
    entity: 'Goal | Goals',
    attributes: {
      title: 'Title',
      description: 'Description',
      duration: 'Duration',
      hours: 'hrs',
      startDate: 'Start Date',
      endDate: 'End Date',
      dates: 'Dates',
      year: 'Year',
      assignee: 'Assignee',
      reviewer: 'Reviewer',
      isPrivate: 'Private',
      progress: 'Progress',
      status: 'Status',
      statuses: {
        pending: 'Pending',
        approved: 'Approved',
        rejected: 'Rejected'
      },
      states: {
        created: 'Created',
        in_progress: 'In Progress',
        achieved: 'Achieved'
      },
      supportingDocument: 'Supporting Document'
    }
  },
  worklog: {
    entity: 'work log | Work Logs',
    attributes: {
      description: 'What are you working on?',
      jiraTask: 'Jira Task',
      startTime: 'From',
      endTime: 'To',
      duration: 'Duration'
    }
  },
  helpDesk: {
    entity: 'Help Desk | Help Desks',
    attributes: {
      name: 'Name',
      link: 'Link'
    }
  }
};
